import { IS_DEV_QUERY } from "@/__main__/constants.mjs";
import lolRefs from "@/game-lol/refs.mjs";

export async function setup() {
  if (!IS_DEV_QUERY) return;
  const component = await import("feature-lol-dev/PostMatchDev.jsx");
  lolRefs.PostMatchDevSection = component.default;
}

export function teardown() {
  lolRefs.PostMatchDevSection = null;
}
